import {
  Box,
  Button,
  Flex,
  Grid,
  Group,
  Skeleton,
  Table,
  Text,
  useMantineTheme,
} from '@mantine/core';
import classes from './ActivityFinancialReport.module.scss';
import { useLazyQuery } from '@apollo/client';
import { FinanceReportFiltersQuery } from 'graphql/queries';
import { showErrorMessage } from 'utils/showErrorMessage/showErrorMessage';
import { useEffect, useMemo, useState } from 'react';
import { FilterSelect } from 'components/FilterSelect';
import { DateFilters, getEnumKeyByEnumValue } from 'utils/bookingsFilters/filters';
import dayjs from 'dayjs';
import { DateFilter } from 'components/DateFilter';
import queryString from 'query-string';
import { downloadFile } from 'utils/downloadFile/downloadFile';
import { getShortISOString } from 'utils/getShortIsoDate/getShortIsoDate';
import { LabelWithPopover } from 'components/LabelWithPopover/LabelWithPopover';
import { formatPrice } from 'utils/formatPrice';
import { Actions, trackAction } from 'utils/amplitude';
import { IntercomEvents, showNewIntercomMessage, trackIntercomEvent } from '@utils';
import { FinancialReportActivities } from 'types';
import { ITotalAndUpcomingPaymentsData } from 'interfaces';
import classNames from 'classnames';
import { CaretDown, DownloadSimple, FileArrowDown } from '@phosphor-icons/react';
import { useMediaQuery } from '@mantine/hooks';
import { PebbleButtonSet, PebbleIconButtonSet } from 'components/common';
import { PebbleButtonsEnum } from 'enums';

interface IActivityFinancialReportProps {
  token: string;
  supplierId: string;
  supplierName: string;
  dateFilterActive: boolean;
  setDateFilterActive(val: boolean): void;
}
interface IActivitiesLabelAndValue {
  label: string;
  value: string | null;
}

type AllDateFilterType = {
  dateFilter: DateFilters | null;
  customDateFilter: [Date | null, Date | null];
};

const ActivityFinancialReport: React.FC<IActivityFinancialReportProps> = ({
  token,
  supplierId,
  supplierName,
  dateFilterActive,
  setDateFilterActive,
}: IActivityFinancialReportProps) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`, true);

  const [showError, setShowError] = useState(false);

  const [getData, { loading, data }] = useLazyQuery<ITotalAndUpcomingPaymentsData>(
    FinanceReportFiltersQuery,
    {
      context: {
        headers: {
          Authorization: `${token}`,
        },
      },
      fetchPolicy: 'network-only',
      onError: (error) => {
        console.log(error);
        showErrorMessage(error);
      },
    },
  );

  const defaultDate: AllDateFilterType = {
    dateFilter: DateFilters['All dates'],
    customDateFilter: [null, null],
  };

  const [selectedActivityFilter, setSelectedActivityFilter] = useState<string>('all-activities');

  const [allDateFilter, setAllDateFilter] = useState<AllDateFilterType>(defaultDate);

  const { dateFilter, customDateFilter } = allDateFilter;

  const activityFilters = useMemo(() => {
    const activitiesFromAPI = data?.financeReportFiltersAndTotals?.activities || [];

    const getLabelAndValueActivities = activitiesFromAPI.map(
      (activity: FinancialReportActivities) => ({
        label: activity.name,
        value: activity.id,
      }),
    );

    const activityLabels: IActivitiesLabelAndValue[] = [
      { label: 'All activities', value: 'all-activities' },
      ...getLabelAndValueActivities,
    ];

    return activityLabels;
  }, [data?.financeReportFiltersAndTotals?.activities]);

  const dateFilterLabel = useMemo(() => {
    const [min, max] = customDateFilter;

    if (min && max) {
      const dayJsMin = dayjs(min);
      const dayJsMax = dayjs(max);
      const isSame = dayJsMin.isSame(dayJsMax, 'day');
      if (isSame) {
        return dayjs(min).format('DD MMM YYYY');
      }
      const isSameYear = dayJsMin.isSame(dayJsMax, 'year');
      const minFormat = isSameYear ? 'DD MMM' : 'DD MMM YYYY';
      return `${dayjs(min).format(minFormat)} - ${dayjs(max).format('DD MMM YYYY')}`;
    }
    if (min) {
      return dayjs(min).format('DD MMM YYYY');
    }
    if (dateFilter) {
      return getEnumKeyByEnumValue(DateFilters, dateFilter);
    }
    return '';
  }, [customDateFilter, dateFilter]);

  const getStartAndEndDateFilters = (
    dateSelectedFilter: DateFilters | null,
    customDateSelectedFilter: [Date | null, Date | null],
  ): Partial<{ datetimeStart: string | null; datetimeEnd: string | null }> => {
    const todayUTC = dayjs(new Date()).utc();
    const [min, max] = customDateSelectedFilter;

    if (min && max) {
      return {
        datetimeStart: dayjs.utc(min).toISOString(),
        datetimeEnd: dayjs.utc(max).add(1, 'day').toISOString(),
      };
    }

    if (min) {
      return {
        datetimeStart: dayjs.utc(min).toISOString(),
        datetimeEnd: dayjs.utc(min).add(1, 'day').toISOString(),
      };
    }

    switch (dateSelectedFilter) {
      case DateFilters.Past:
        return {
          datetimeEnd: todayUTC.startOf('day').toISOString(),
        };
      case DateFilters.Upcoming:
        return {
          datetimeStart: todayUTC.startOf('day').toISOString(),
        };

      case DateFilters['All dates']:
      default:
        return {};
    }
  };

  useEffect(() => {
    if (supplierId) {
      getData({
        variables: {
          supplierId,
          ...getStartAndEndDateFilters(allDateFilter.dateFilter, allDateFilter.customDateFilter),
        },
      });
    }
  }, [supplierId, allDateFilter, getData]);

  const requestReportUrl = async () => {
    const { datetimeStart, datetimeEnd } = getStartAndEndDateFilters(dateFilter, customDateFilter);

    const params = {
      ...(datetimeStart && {
        datetime_start: getShortISOString(datetimeStart),
      }),
      ...(datetimeEnd && {
        datetime_end: getShortISOString(datetimeEnd),
      }),
      ...(selectedActivityFilter !== 'all-activities' && {
        activity_id: selectedActivityFilter,
      }),
    };

    const paramsString = queryString.stringify(params);

    let endpointUrl = `${process.env.NEXT_PUBLIC_REPORTS_ENDPOINT}/activity_transactions/${supplierId}`;

    if (datetimeStart || datetimeEnd || selectedActivityFilter !== 'all-activities') {
      endpointUrl = `${process.env.NEXT_PUBLIC_REPORTS_ENDPOINT}/activity_transactions/${supplierId}?${paramsString}`;
    }

    const formatSupplierName = supplierName.replace(/\s/g, '');

    const fileName = `Activity_Transactions_${formatSupplierName}.csv`;

    try {
      const response = await fetch(endpointUrl, {
        headers: {
          Authorization: token,
        },
      });
      const { reportUrl } = await response.json();
      await downloadFile(reportUrl, fileName);
    } catch (e) {
      showErrorMessage(e);
    }
  };

  return (
    <>
      <Grid mt="sm" mb="sm" grow>
        <Grid.Col span={{ base: 4, md: 6 }}>
          <Text mb="sm" size="16px">
            Total payments in the last 30 days:
          </Text>
          <Box>
            {data && (
              <Flex align="center" className={classes.paymentWrapper} justify="space-between">
                <Flex align="end">
                  <Text size="20px" fw={700}>
                    {formatPrice(data?.financeReportFiltersAndTotals.totalSucceededLast30Days)}
                  </Text>
                  {data?.financeReportFiltersAndTotals.totalSucceededLast30Days > 0 && (
                    <Text size="16px" c={theme.colors.gray[5]} fw={600} ml="sm">
                      Spent
                    </Text>
                  )}
                </Flex>
                <Flex>
                  {data && (
                    <LabelWithPopover
                      largeIcon
                      boldInfoIcon={false}
                      labelText=""
                      size="sm"
                      popoverContent={
                        <>
                          <Text>Total completed transfers (fees have been deducted).</Text>
                          <br />
                          <Text>Cancelled bookings are excluded.</Text>
                        </>
                      }
                    />
                  )}
                </Flex>
              </Flex>
            )}
          </Box>
        </Grid.Col>
        <Grid.Col span={{ base: 4, md: 6 }} className={classes.totalUpcomingPayments}>
          <Text mb="sm" size="16px">
            Total upcoming payments:
          </Text>
          <Box>
            {data && (
              <Flex align="center" className={classes.paymentWrapper} justify="space-between">
                <Flex align="end">
                  <Text size="20px" fw={700}>
                    {formatPrice(data?.financeReportFiltersAndTotals.totalPending)}
                  </Text>
                  {data?.financeReportFiltersAndTotals.totalPending > 0 && (
                    <Text size="16px" c={theme.colors.gray[5]} fw={600} ml="sm">
                      Spent
                    </Text>
                  )}
                </Flex>
                <Flex>
                  {data && (
                    <LabelWithPopover
                      largeIcon
                      boldInfoIcon={false}
                      labelText=""
                      size="sm"
                      popoverContent={
                        <Text>
                          Total upcoming payments (after booking fee) due to arrive in your Stripe
                          account.
                        </Text>
                      }
                    />
                  )}
                </Flex>
              </Flex>
            )}
          </Box>
        </Grid.Col>
      </Grid>
      <Flex justify={isMobile ? 'space-between' : 'flex-start'} align="center" mt="md">
        <Text size="16px" fw={600} mr={isMobile ? 0 : 'xs'}>
          Export a breakdown of your payments
        </Text>
        <LabelWithPopover
          largeIcon
          boldInfoIcon={false}
          labelText=""
          size="sm"
          popoverContent={
            <Text
              style={{
                fontSize: 14,
              }}
            >
              Here you can view a breakdown of payments made by customers and transferred to your
              stripe account, as well as pending payments. Select a date or date range to export
              bookings made within that period.
            </Text>
          }
        />
      </Flex>
      <Group pt={theme.spacing.lg} className={classes.toolbar}>
        {loading ? (
          <Table>
            <tbody>
              <tr>
                <td>Loading...</td>
                <td className="tdNoResult" colSpan={4}>
                  <Skeleton height={24} mb="sm" width="100%" radius="xs" />
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <Box className={classes.buttonWrapper}>
            <Button
              w={isMobile ? '100%' : '125px'}
              onClick={() => {
                setShowError(false);
                setDateFilterActive(!dateFilterActive);
              }}
              classNames={{
                // root: classes.filterButton,
                root: classNames(classes.filterButton, {
                  [classes.filterSelected]: dateFilterLabel !== 'All dates',
                }),
                label: classes.filterButtonLabel,
                section: classNames({
                  [classes.buttonIconRotate]: dateFilterActive,
                }),
              }}
            >
              {dateFilterLabel} <CaretDown size={18} weight="bold" color={theme.colors.gray[6]} />
            </Button>
            <DateFilter
              isHomePage={true}
              open={dateFilterActive}
              onClose={() => setDateFilterActive(false)}
              activeFilter={customDateFilter[0] ? null : dateFilter}
              activeCustomDateFilter={customDateFilter}
              onDateFilterChange={(filter) => {
                setAllDateFilter({
                  customDateFilter: [null, null],
                  dateFilter: filter,
                });
              }}
              onCustomDateChange={(dates) => {
                setAllDateFilter({
                  customDateFilter: dates,
                  dateFilter: dates[0] ? null : DateFilters['All dates'],
                });
              }}
            />

            <FilterSelect
              financeDashboardStyling
              defaultValue={'all-activities'}
              value={selectedActivityFilter}
              onChange={(value: string | null) => {
                if (!value) return;
                trackAction(Actions.DASH_ACTIVITY_PICKER);
                setSelectedActivityFilter(value);
              }}
              onFocus={() => setShowError(false)}
              // eslint-disable-next-line
              // @ts-ignore
              data={activityFilters}
            />
            {isMobile ? (
              <>
                <PebbleButtonSet
                  btnVariant={PebbleButtonsEnum.SECONDARY}
                  fullWidth
                  size="md"
                  onClick={async () => {
                    if (data?.financeReportFiltersAndTotals?.activities.length === 0) {
                      setShowError(true);
                    } else {
                      await requestReportUrl();
                    }
                    trackAction(Actions.DASH_EXPORT_FINANCE);
                    trackIntercomEvent(IntercomEvents.EXPORT_FINANCES);
                  }}
                  leftSection={<FileArrowDown size={26} />}
                >
                  Export
                </PebbleButtonSet>
              </>
            ) : (
              <>
                <PebbleIconButtonSet
                  btnVariant={PebbleButtonsEnum.SECONDARY}
                  size="md"
                  mr="sm"
                  icon={<DownloadSimple size="18px" />}
                  onClick={async () => {
                    if (data?.financeReportFiltersAndTotals?.activities.length === 0) {
                      setShowError(true);
                    } else {
                      await requestReportUrl();
                    }
                    trackAction(Actions.DASH_EXPORT_FINANCE);
                    trackIntercomEvent(IntercomEvents.EXPORT_FINANCES);
                  }}
                />
              </>
            )}
          </Box>
        )}
      </Group>
      {showError && (
        <Text c="red" size="sm">
          There are no bookings for these date(s)
        </Text>
      )}
      <Flex align="center" mt="md">
        <Text mb={0} size="14px" lh="18px" fw={700}>
          Can't find the data you need? Chat to our customer support team{' '}
          <Button
            variant="transparent"
            component="a"
            className={classes.hereLink}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              showNewIntercomMessage();
            }}
          >
            here.
          </Button>
        </Text>
      </Flex>
    </>
  );
};

export default ActivityFinancialReport;
